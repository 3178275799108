import React from 'react';
import { theme } from '../theme';
import { Layout } from '../layouts';
import { graphql, Link } from 'gatsby';
import { distanceInWordsToNow } from 'date-fns';
import { LinkArea, LinkAreaRenderProps } from '../components/LinkArea';
import { Container } from '../components/Container';
import { Text } from '../components/Text/Text';
import { Label } from '../components/Inputs/Label';
import { AccentHeading } from '../components/Text/AccentHeading';
import { Heading } from '../components/Text/Heading';
import { Helmet } from 'react-helmet';
import { Head } from '../components/Head';

export interface CareersProps {
  data: GatsbySchema.Query;
}

export default class Careers extends React.Component<CareersProps> {
  render() {
    const {
      data: {
        allMarkdownRemark: { edges: jobs },
      },
    } = this.props;

    return (
      <Layout>
        <Head title="Careers" description="Careers at The Palmer Group" />
        <Container padding={['all']}>
          <div
            css={{
              marginBottom: '6rem',
            }}
          >
            <AccentHeading>Careers</AccentHeading>
            <Heading component="h1">Open Positions</Heading>
          </div>

          {jobs.map(({ node: job }) => {
            return (
              <LinkArea
                css={{
                  display: 'flex',
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderBottomColor: theme.color.offWhite,
                  transition: 'background 200ms ease-out',
                  flexDirection: 'column',
                  '&:hover': {
                    background: 'rgba(240,240,240,.4)',
                  },
                  ['&:hover [data-animated]' as string]: {
                    transform: 'translateX(1rem)',
                  },
                  [theme.media.medium]: {
                    flexDirection: 'row',
                  },
                }}
              >
                {({ link }: LinkAreaRenderProps) => (
                  <React.Fragment>
                    <div
                      data-animated
                      css={{
                        marginTop: '2rem',
                        transition: 'transform 200ms ease-out',
                        [theme.media.medium]: {
                          padding: '0 2rem',
                          width: '25%',
                        },
                      }}
                    >
                      <Link
                        to={`/careers/${job.frontmatter.slug}`}
                        innerRef={link}
                      >
                        <Text size={1} css={{ fontWeight: theme.bold }}>
                          {job.frontmatter.title}
                        </Text>
                      </Link>
                      <Text>{job.frontmatter.location}</Text>
                      <Label
                        component="div"
                        css={{
                          color: theme.color.grayDarker,
                          marginLeft: 'auto',
                          marginTop: '.25rem',
                          whiteSpace: 'nowrap',
                          [theme.media.medium]: {
                            marginTop: '2rem',
                          },
                        }}
                      >
                        {distanceInWordsToNow(job.frontmatter.date, {
                          addSuffix: true,
                        })}
                      </Label>
                    </div>
                    <div
                      css={{
                        marginTop: '2rem',
                        marginBottom: '2rem',
                        lineHeight: 1.5,
                        [theme.media.medium]: {
                          width: '75%',
                          padding: '0 2rem',
                        },
                      }}
                    >
                      {job.frontmatter.description}
                    </div>
                  </React.Fragment>
                )}
              </LinkArea>
            );
          })}
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query Careers {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/jobs/**" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
            description
            location
          }
        }
      }
    }
  }
`;
