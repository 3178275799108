import React from 'react';
import { theme } from '../theme';

type Paddings = 'all' | 'top' | 'right' | 'bottom' | 'left';
type Sizes = 'normal' | 'large';

export interface ContainerProps {
  padding?: Paddings[];
  background?: keyof typeof theme.color;
  children?: React.ReactNode;
  maxWidth?: number;
  size?: Sizes;
  css?: any;
  className?: string;
  dotBackground?: boolean;
}

export const Container: React.SFC<ContainerProps> = ({
  padding = [],
  background,
  children,
  maxWidth = theme.containerWidth,
  dotBackground = false,
  size = 'normal',
}) => {
  const vert = size === 'large' ? '12rem' : '5rem';
  return (
    <div
      css={{
        position: 'relative',
        overflow: 'hidden',
        background: theme.color[background],
        ...(padding.indexOf('all') >= 0 ? { padding: `${vert} 1rem` } : {}),
        paddingBottom: padding.indexOf('bottom') >= 0 && vert,
        paddingLeft: padding.indexOf('left') >= 0 && '1rem',
        paddingRight: padding.indexOf('right') >= 0 && '1rem',
        ...(dotBackground
          ? {
              backgroundImage: `radial-gradient(circle, #D7D7D7, #D7D7D7 1px, #FFF 1px, #FFF)`,
              backgroundSize: `28px 28px`,
            }
          : {}),
        paddingTop: padding.indexOf('top') >= 0 && vert,
        [theme.media.medium]: {
          ...(padding.indexOf('all') >= 0 ? { padding: `${vert} 2rem` } : {}),
          paddingLeft: padding.indexOf('left') >= 0 && '2rem',
          paddingRight: padding.indexOf('right') >= 0 && '2rem',
        },
      }}
    >
      <div
        css={{
          maxWidth,
          margin: '0 auto',
        }}
      >
        {children}
      </div>
    </div>
  );
};

(Container as any).displayName = 'Container';
