import React from 'react';
import { Link } from 'gatsby';
import { Location, LocationContext, Match } from '@reach/router';
import { theme } from '../../theme';
import { NavLink } from './NavLink';
import { NavHamburger } from './NavHamburger';
import { NavMobile } from './NavMobile';
import { Links } from '../../constants';

interface NavImplState {
  open: boolean;
  scrolled: boolean;
}

class NavImpl extends React.Component<LocationContext, NavImplState> {
  state: NavImplState = {
    open: !!this.props.location.state && this.props.location.state.navOpen,
    scrolled: false,
  };

  componentDidMount() {
    const { navigate, location } = this.props;
    const { open } = this.state;

    window.addEventListener('scroll', this.handleScroll);

    if (open) {
      this.setState({ open: false }, () => {
        navigate(location.pathname, {
          state: { navOpen: false },
          replace: true,
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.scrollTo(0, 0);
  }

  handleScroll = () => {
    const { scrolled } = this.state;
    const currentlyScrolled = window.scrollY > 0;

    if (currentlyScrolled !== scrolled) {
      this.setState({ scrolled: currentlyScrolled });
    }
  };

  toggleNav = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const { open, scrolled } = this.state;
    return (
      <div
        css={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 20,
          background: scrolled ? theme.color.white : 'transparent',
          boxShadow: scrolled ? theme.shadow.medium : 'none',
          transition: 'box-shadow 150ms',
        }}
      >
        <NavMobile isOpen={open} items={Links} toggleNav={this.toggleNav} />
        <NavHamburger isOpen={open} toggleNav={this.toggleNav} />
        <div
          css={{
            height: 50,
            width: '100%',
            padding: '0 1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.media.medium]: {
              justifyContent: 'space-between',
              height: 80,
              padding: '0 2rem',
            },
          }}
        >
          <Match path="/">
            {({ match }) => (
              <Link
                css={{
                  fontWeight: 700,
                  textDecoration: 'none',
                  textTransform: 'uppercase',
                  color: theme.color.black,
                  fontSize: 20,
                  letterSpacing: '1px',
                }}
                to="/"
                state={{ navOpen: open }}
                onClick={match && open ? this.toggleNav : null}
              >
                <span
                  css={{
                    display: 'none',
                    [theme.media.medium]: { display: 'inline' },
                  }}
                >
                  The{' '}
                </span>
                <span
                  css={{
                    position: 'relative',
                    transition: 'color 150ms',
                    color: open ? theme.color.white : theme.color.black,
                    [theme.media.medium]: {
                      color: theme.color.black,
                    },
                  }}
                >
                  Palmer
                </span>
                <span
                  css={{
                    display: 'none',
                    [theme.media.medium]: { display: 'inline' },
                  }}
                >
                  {' '}
                  Group
                </span>
              </Link>
            )}
          </Match>
          <div
            css={{
              display: 'none',
              [theme.media.medium]: {
                flex: '1',
                maxWidth: 500,
                alignItems: 'center',
                justifyContent: 'flex-end',
                display: 'flex',
                whiteSpace: 'nowrap',
              },
            }}
          >
            {Links.map(({ to, href, text }) => (
              <NavLink
                key={to}
                href={href}
                to={to}
                text={text}
                css={{ marginLeft: '1rem' }}
              >
                {text}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export const Nav: React.SFC<{}> = props => (
  <Location>{location => <NavImpl {...props} {...location} />}</Location>
);
