import * as React from 'react';
import { Helmet } from 'react-helmet';

export interface HeadProps {
  title?: string;
  description?: string;
  image?: string;
  children?: any;
}

export const Head: React.SFC<HeadProps> = ({
  title,
  description,
  image,
  children,
  ...rest
}) => {
  return (
    <Helmet
      meta={[
        title && {
          property: 'og:title',
          content: `${title}`,
        },
        title && {
          name: 'twitter:title',
          content: `${title} | The Palmer Group`,
        },
        description && {
          name: 'description',
          content: description,
        },
        description && {
          name: 'twitter:description',
          content: description,
        },
        description && {
          name: 'og:description',
          content: description,
        },
        image && {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        image && {
          name: 'twitter:image',
          content: image,
        },
        image && {
          name: 'og:image',
          content: image,
        },
      ].filter(Boolean)}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      {children}
    </Helmet>
  );
};

(Head as any).displayName = 'Head';
