import React from 'react';
import { theme } from '../../theme';
import { css as glamor, StyleProps } from 'glamor';

const sizes = ['4rem', '3rem', '1.5rem'];

export interface HeadingProps extends StyleProps {
  size?: number;
  color?: keyof typeof theme.color;
  component?: React.ComponentType | string;
}

const defaultStyle = {
  fontWeight: theme.bold,
  letterSpacing: '-.03em',
  lineHeight: 1.1,
  wordWrap: 'break-word',
};

export const Heading: React.SFC<HeadingProps> = ({
  className,
  component: Component = 'div',
  size = 0,
  color = 'inherit',
  ...rest
}) => (
  <Component
    {...glamor([
      defaultStyle,
      { fontSize: sizes[size], color: theme.color[color] },
      className,
    ])}
    {...rest}
  />
);
