import React from 'react';
import { theme } from '../../theme';
import Toggle from 'react-toggled';

type NavHamburgerProps = {
  isOpen: boolean;
  toggleNav: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const NavHamburger: React.SFC<NavHamburgerProps> = ({
  isOpen,
  toggleNav,
}) => {
  return (
    <Toggle on={isOpen}>
      {({ getElementTogglerProps }) => (
        <div
          css={{
            position: 'absolute',
            height: 50,
            top: '0',
            left: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            cursor: 'pointer',
            [theme.media.medium]: {
              display: 'none',
            },
          }}
          aria-label="Menu"
          role="button"
          {...getElementTogglerProps({ onClick: toggleNav })}
        >
          {isOpen ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              strokeWidth="2"
              style={{ verticalAlign: 'middle' }}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <g className="nc-icon-wrapper">
                <path d="M18 6L6 18" />
                <path d="M6 6l12 12" />
              </g>
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke={theme.color.black}
              style={{ verticalAlign: 'middle' }}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <g className="nc-icon-wrapper">
                <path d="M3 12h18" />
                <path d="M3 6h18" />
                <path d="M3 18h18" />
              </g>
            </svg>
          )}
        </div>
      )}
    </Toggle>
  );
};

(NavHamburger as any).displayName = 'Hamburger';
