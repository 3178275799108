import React from 'react';
import { CSSProp } from 'glamor';
import { Nav } from '../components/Nav/Nav';
import { Helmet } from 'react-helmet';
import logo from '../images/logo.png';
import { theme } from '../theme';

export interface LayoutProps {
  children?: any;
  css?: CSSProp;
}

export const Layout: React.SFC<LayoutProps> = props => {
  return (
    <>
      <Helmet
        defaultTitle="The Palmer Group"
        titleTemplate="%s | The Palmer Group"
        meta={[
          { charset: 'utf-8' },
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:site', content: '@palmergrouphq' },
          {
            property: 'og:image',
            content: `https://palmer.net${logo}`,
          },
          {
            name: 'twitter:image',
            content: `https://palmer.net${logo}`,
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Nav />
      <div
        role="main"
        css={{
          minHeight: '100%',
          background: '#FFF',
          marginTop: '50px',
          [theme.media.medium]: {
            marginTop: '80px',
          },
        }}
        {...props}
      />
    </>
  );
};
