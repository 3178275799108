import React from 'react';
import { StyleProps } from 'glamor';
import { theme } from '../../theme';

export interface LabelProps extends StyleProps {
  component?: string | React.ComponentType;
}

const defaultStyles = {
  display: 'block',
  textTransform: 'uppercase',
  color: theme.color.grayDarker,
  fontWeight: theme.bold,
  marginBottom: '.5rem',
  fontSize: '.75rem',
};

export const Label: React.SFC<LabelProps> = ({
  component: Component = 'label',
  className,
  ...props
}) => <Component css={[defaultStyles, className]} {...props} />;
