import React from 'react';
import { theme } from '../../theme';
import { before, css } from 'glamor';

interface AccentHeadingProps {
  color?: keyof typeof theme.color;
}

export const AccentHeading: React.SFC<AccentHeadingProps> = ({
  children,
  color = 'grayDarker',
}) => (
  <div
    {...css(
      {
        fontSize: '1rem',
        fontWeight: 500,
        paddingTop: '.25rem',
        letterSpacing: '.03em',
        lineHeight: 1.2,
        width: '100%',
        marginBottom: '.5rem',
        paddingLeft: '4rem',
        textTransform: 'uppercase',
        position: 'relative',
        color: theme.color[color],
      },
      before({
        position: 'absolute',
        left: 0,
        top: '50%',
        marginTop: -1,
        height: 2,
        width: '3rem',
        content: '""',
        background: theme.color.green,
      })
    )}
  >
    {children}
  </div>
);
