import React from 'react';
import { Link } from 'gatsby';
import { theme } from '../../theme';
import { css } from 'glamor';

export const NavLink = React.forwardRef<any, any>(
  ({ text, className, to, href, state, ...props }, ref) => {
    if (href) {
      return (
        <a
          ref={ref}
          css={[
            {
              display: 'block',
              textDecoration: 'none',
              fontWeight: '500',
              color: theme.color.grayDarker,
              textTransform: 'uppercase',
              fontSize: '.9rem',
              transition: 'color 200ms ease-out',
              '&:hover': {
                color: theme.color.grayLighter,
              },
            },
            className,
          ]}
          href={href}
          {...props}
        />
      );
    }

    return (
      <Link
        ref={ref}
        state={state}
        to={to}
        css={[
          {
            display: 'block',
            textDecoration: 'none',
            fontWeight: '500',
            color: theme.color.grayDarker,
            textTransform: 'uppercase',
            fontSize: '.9rem',
            transition: 'color 200ms ease-out',
            '&:hover': {
              color: theme.color.grayLighter,
            },
          },
          className,
        ]}
        {...props}
      />
    );
  }
);

NavLink.displayName = 'NavLink';
