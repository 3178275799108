import React from 'react';
import { StyleProps } from 'glamor';
import { theme } from '../../theme';

const sizes = ['1.4rem', '1.25rem', '1rem', '.875rem'];

export interface TextProps extends StyleProps {
  component?: React.ComponentType | string;
  size?: number;
  color?: keyof typeof theme.color | 'inherit';
}

const defaultTextStyle = {
  letterSpacing: '-.02em',
  lineHeight: 1.4,
};

export const Text: React.SFC<TextProps> = ({
  component: Component = 'div',
  className,
  size = 2,
  color = 'inherit',
  ...props
}) => {
  return (
    <Component
      css={[
        defaultTextStyle,
        { fontSize: sizes[size], color: theme.color[color] },
        className,
      ]}
      {...props}
    />
  );
};
