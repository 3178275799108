import React, { PureComponent } from 'react';
import { theme } from '../../theme';
import { NavLink } from './NavLink';
import { config, animated, Trail, Transition } from 'react-spring';
import { Match } from '@reach/router';

interface NavMobileProps {
  isOpen: boolean;
  items: { href?: string; to?: string; text: string }[];
  toggleNav: () => void;
}

interface NavMobileState {
  mounted: boolean;
}

export class NavMobile extends React.Component<NavMobileProps> {
  state: NavMobileState = {
    mounted: false,
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const { isOpen, items, toggleNav } = this.props;
    const { mounted } = this.state;

    return (
      <Transition
        config={{
          tension: 30,
          friction: 8,
          overshootClamping: true,
        }}
        native
        initial={{ transform: 'translateY(0px)', opacity: 1 }}
        from={{ transform: 'translateY(-50px)', opacity: 1 }}
        enter={{ transform: 'translateY(0px)', opacity: 1 }}
        leave={{ transform: 'translateY(-50px)', opacity: 0 }}
      >
        {isOpen &&
          (style => (
            <animated.div
              style={style}
              css={{
                position: 'absolute',
                left: 0,
                right: 0,
                boxShadow: '0 2px 4px rgba(0,0,0,.2)',
                pointerEvents: isOpen ? 'auto' : 'none',
                backgroundColor: '#000',
                paddingTop: '3rem',
                paddingBottom: '2rem',
                paddingLeft: '3rem',
                paddingRight: '3rem',
                [theme.media.medium]: {
                  display: 'none',
                },
              }}
            >
              <Trail
                keys={items.map(({ to }) => to)}
                config={config.stiff}
                immediate={!mounted}
                from={{
                  transform: 'translateY(-10px)',
                  opacity: 0,
                }}
                to={{ transform: 'translateY(0)', opacity: 1 }}
              >
                {items.map(
                  ({ to, href, text }) => (styles: {
                    transform: string;
                    opacity: number;
                  }) => (
                    <Match key={to || href} path={to || href}>
                      {({ match }) => (
                        <NavLink
                          style={styles}
                          css={{
                            padding: '1rem 0',
                            color: '#eee',
                            fontSize: '1rem',
                            ':not(:first-child)': {
                              borderTop: `1px solid ${theme.color.grayDarker}`,
                            },
                          }}
                          to={to}
                          href={href}
                          state={{ navOpen: true }}
                          onClick={match && toggleNav}
                        >
                          {text}
                        </NavLink>
                      )}
                    </Match>
                  )
                )}
              </Trail>
            </animated.div>
          ))}
      </Transition>
    );
  }
}

(NavMobile as any).displayName = 'NavMobile';
