export const Links = [
  // @todo update job posts and publish
  // {
  //   text: 'Careers',
  //   to: '/careers',
  // },
  {
    text: 'Clients',
    to: '/clients',
  },
  {
    text: 'Open Source',
    to: '/open-source',
  },
  {
    text: 'Blog',
    to: '/blog',
  },
  {
    text: 'Contact',
    to: '/contact',
  },
  {
    text: 'Shelly Palmer',
    href: 'https://www.shellypalmer.com',
  },
];
